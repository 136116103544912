import React, { useState } from "react";
import VideoModal from "../video-modal/video-modal";
import VideoImage from "../video-image/video-image";

const VideoCard = ({ id, idSuffix, image, footerContent, bodyContent, video }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div id={`${id}${idSuffix ? `-${idSuffix}` : ""}`} className={`col mb-4 mb-lg-0 card bg-transparent border-0`}>
      {/** Image/Header */}

      <VideoImage
        image={image}
        id={`${id}-button${idSuffix ? `-${idSuffix}` : ""}`}
        imageCl="card-image-top"
        onClick={() => setShowModal(true)}
        imageClassName="card-image-top border-radius-12"
      />
      {/** Body */}
      <div className="card-body px-0 d-flex flex-column align-items-start">{bodyContent}</div>
      {/** Footer */}
      <div className="card-footer bg-transparent border-0 px-0">{footerContent}</div>
      {/**Video Modal */}
      <VideoModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        id={video.id}
        VimeoID={video.vimeoId}
        Title={video.title}
      />
    </div>
  );
};
export default VideoCard;

VideoCard.defaultProps = {
  id: "video-card",
  idSuffix: "1",
  image: {
    imgVariable: "",
    altText: "Image"
  },
  footerContent: <></>,
  bodyContent: <></>,
  video: {
    id: "video",
    vimeoId: "123456789",
    title: "Video Title"
  }
};
